<template>
    <div class="page">
        <app-header :navIndex="1"></app-header>
        <div class="inner-box section-container d-flex flex-row pt-12 pb-12">
            <div class="col-md-9 pr-md-12">
                <div class="ql-container">
                    <div class="ql-editor" v-html="news.details">
                    </div>
                </div>
                <!--<div v-html="news.details">
                </div>-->
                <div class="pt-12 pb-12" v-if="false">
                    {{$i18n.t('common.share')}}
                    <span class="iconfont iconweixin pa-2 ml-6"></span>
                    <span class="iconfont iconweibo pa-2"></span>
                </div>
            </div>
            <div class="col-md-3 mt-12 pt-12 pl-12 hidden-md-and-down">
                <div class="more pl-2 mb-3">{{$i18n.t('common.moreBtn')}}</div>
                <div class="mb-6 news" v-for="(item,index) in newsList" @click="lookNews(item.id)">
                    <v-img :src="$utils.formatImgUrl(item.imgUrl)"></v-img>
                    <div class="title mt-3">{{item.title}}</div>
                    <div class="time black--text pb-3">{{item.time}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import news from "../../config/news"
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {AppHeader},
        data(){
            return {
                news: news,
                newsList: this.$i18n.t('others.news.list'),
                id: '',
            }
        },
        created(){
            this.id = this.$route.query.id
            this.initData()
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "详情"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            initData(){
                this.$api.web.getNewsDetails({id: this.id}).then(res => {
                    this.news = {details: res.details}
                    this.newsList = res.list
                })
            },
            lookNews(id){
                this.$router.push({path: '/company/newsDetails', query: {id: id}})
                window.location.reload();
            }
        }
    }
</script>
<style>
    img{
        max-width: 100%;
    }
</style>
<style lang="scss" scoped>
    .inner-box {
        margin-top: 60px;
    }

    .iconfont {
        cursor: pointer;
    }

    .more {
        font-size: 18px;
        border-left: 4px solid $primary-color;
        color: $primary-color;
        .title {
            color: $primary-color;
            font-size: 20px;
        }
        .time {
            font-size: 20px;
        }
    }

    .news {
        cursor: pointer;
    }
</style>
